@font-face {
    font-family: 'Koulen';
    src: url('../fonts/Koulen-Regular.eot');
    src: url('../fonts/Koulen-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Koulen-Regular.woff2') format('woff2'),
        url('../fonts/Koulen-Regular.woff') format('woff'),
        url('../fonts/Koulen-Regular.ttf') format('truetype'),
        url('../fonts/Koulen-Regular.svg#Koulen-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Mulish';
    src: url('../fonts/Mulish-ExtraLight.eot');
    src: url('../fonts/Mulish-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Mulish-ExtraLight.woff2') format('woff2'),
        url('../fonts/Mulish-ExtraLight.woff') format('woff'),
        url('../fonts/Mulish-ExtraLight.ttf') format('truetype'),
        url('../fonts/Mulish-ExtraLight.svg#Mulish-ExtraLight') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src: url('../fonts/Mulish-Bold.eot');
    src: url('../fonts/Mulish-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Mulish-Bold.woff2') format('woff2'),
        url('../fonts/Mulish-Bold.woff') format('woff'),
        url('../fonts/Mulish-Bold.ttf') format('truetype'),
        url('../fonts/Mulish-Bold.svg#Mulish-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src: url('../fonts/Mulish-ExtraBold.eot');
    src: url('../fonts/Mulish-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Mulish-ExtraBold.woff2') format('woff2'),
        url('../fonts/Mulish-ExtraBold.woff') format('woff'),
        url('../fonts/Mulish-ExtraBold.ttf') format('truetype'),
        url('../fonts/Mulish-ExtraBold.svg#Mulish-ExtraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src: url('../fonts/Mulish-Light.eot');
    src: url('../fonts/Mulish-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Mulish-Light.woff2') format('woff2'),
        url('../fonts/Mulish-Light.woff') format('woff'),
        url('../fonts/Mulish-Light.ttf') format('truetype'),
        url('../fonts/Mulish-Light.svg#Mulish-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src: url('../fonts/Mulish-Medium.eot');
    src: url('../fonts/Mulish-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Mulish-Medium.woff2') format('woff2'),
        url('../fonts/Mulish-Medium.woff') format('woff'),
        url('../fonts/Mulish-Medium.ttf') format('truetype'),
        url('../fonts/Mulish-Medium.svg#Mulish-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src: url('../fonts/Mulish-SemiBold.eot');
    src: url('../fonts/Mulish-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Mulish-SemiBold.woff2') format('woff2'),
        url('../fonts/Mulish-SemiBold.woff') format('woff'),
        url('../fonts/Mulish-SemiBold.ttf') format('truetype'),
        url('../fonts/Mulish-SemiBold.svg#Mulish-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src: url('../fonts/Mulish-Regular.eot');
    src: url('../fonts/Mulish-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Mulish-Regular.woff2') format('woff2'),
        url('../fonts/Mulish-Regular.woff') format('woff'),
        url('../fonts/Mulish-Regular.ttf') format('truetype'),
        url('../fonts/Mulish-Regular.svg#Mulish-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}



$f_primary: "Koulen";
$f_secondary: "Mulish";

body{
    font-family: $f_primary !important;
    background-color: #000000 !important;
}
.chakra-container{
    max-width: 1280px !important;
}
.def_btn{
    background: linear-gradient(108.11deg, #FFFFFF 14.2%, #E585EF 117.83%) !important;
    border-radius: 0 !important;
    font-family: $f_primary !important;
    font-weight: 400 !important;
    font-size: 19px !important;
    letter-spacing: 0.02em !important;
    color: #000000 !important;
    min-height: 42.62px !important;
    padding: 0 23px;
    display: flex !important;
    align-items: center !important; 
    justify-content: center !important;
    transition: all 0.2s ease-out !important;
    box-shadow: 0px 6px 60px 16px rgb(229 133 239 / 60%) !important;
    transform: scale(1);
    transition: all 0.2s ease-out;
    white-space: nowrap;
    
    cursor: pointer;
    &:hover, &:focus{
        transform: scale(1.03);
    }
    @media screen and (min-width: 576px) {
        width: 188.67px;
    }
    @media screen and (max-width: 575px) {
        font-size: 16px !important;
    }
}
.header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    background: rgba(0, 0, 0, 0.4);
    
    .innr_hdr{
        padding: 10px 96px 10px 30px;
        background: transparent;
        border-bottom: 1px solid #696969;
    }
    .hdr_link{
        font-family: $f_primary;
        font-weight: 400;
        font-size: 24px;
        line-height: 43px;
        text-align: center;
        color: #FFFFFF;
        height: 43px;
        margin: 0 34px;
        transition: all 0.2s ease-out;
        cursor: pointer;
        &:hover, &:focus{
            text-decoration: none;
            color: #f82de0;
        }
    }
    .mrgn_lft_flex{
        margin-right: 68px;
    }
}
@media screen and (max-width: 1500px) {
    .header .innr_hdr{
        padding: 10px 60px 10px 30px;
    }
}
@media screen and (max-width: 1400px) {
    .header .innr_hdr {
        padding: 10px 40px 10px 30px;
    }
    .header .mrgn_lft_flex {
        margin-right: 56px;
    }
    .header .hdr_link{
        margin: 0 30px;
    }
}
@media screen and (max-width: 1279px) {
    .def_btn{
        padding: 0 20px !important;
    }
    .header .innr_hdr {
        padding: 10px 30px 10px 16px;
    }
    .header .mrgn_lft_flex {
        margin-right: 40px;
    }
    .header .hdr_link {
        margin: 0 22px;
    }
    .header .innr_hdr .logo{
        width: 200px;
    }
}
@media screen and (max-width: 1100px) {
    .header .hdr_link {
        margin: 0 16px;
        font-size: 22px;
    }
    .header .mrgn_lft_flex {
        margin-right: 30px;
    }
    .header{
        .def_btn {
            padding: 0 12px !important;
            min-height: 40.62px !important;
        }
    }
}
@media screen and (max-width: 991px) {
    .header{
        .cllps_innr{
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 24px 16px;
            background: rgb(0 0 0 / 60%);
        }
        .tggl_btn{
            background: linear-gradient(108.11deg, #FFFFFF 14.2%, #E585EF 117.83%) !important;
            margin-right: 16px;
        }
    }
    .header .hdr_link{
        margin: 16px 0;
        font-size: 22px;    
        line-height: 24px;
        height: auto !important;
    }
}
@media screen and (max-width: 767px) {
    .header .innr_hdr .logo {
        width: 160px;
    }
}
@media screen and (max-width: 600px) {
    .header .tggl_btn{
        width: 36px;
        min-width: 0;
        height: 36px;
        svg{
            width: 16px;
        }
    }
    .header .def_btn{
        padding: 0 10px !important;
        min-height: 0px !important;
        height: 32px !important;
        font-size: 16px !important;
    }
    .header .innr_hdr {
        padding: 10px 16px 10px 16px;
    }
}
@media screen and (max-width: 479px) {
    .header .hdt_btn_stack{
        position: fixed;
        right: 20px;
        bottom: 20px;
    }
    .header .hdr_link{
        font-size: 20px;
        margin: 12px 0;
    }
}
///// BlockchainSuccess /////
.bckchn_sccss_main_bx{
    min-height: 1190px;
    background: url(../img/blckchn_sc_bg.png) no-repeat right;
    background-size: cover;
    position: relative;
    padding: 358px 0 0 0;
    .def_btn{
        padding: 0 62px !important;
        margin-top: 42px;
        display: inline-flex !important;
        position: relative;
        z-index: 1;
    }
}
.banner_ani {
    position: absolute;
    right: 0;
    top: 0;
    .lottie_space {
        z-index: 25;
        position: absolute;
        height: 590px;
        width: 604px;
        right: calc(50% - 360px);
        top: calc(50% - 440px);
    }
}
.sltns_strtgs_hdng {
    position: relative;
    z-index: 1;
}
@media screen and (max-width: 1470px) {
    .banner_ani {
        top: 7%;
        width: 900px;
        .lottie_space {
            z-index: 25;
            height: 490px;
            width: 504px;
            right: calc(50% - 297px);
            top: calc(50% - 370px);
        }
    }
}
@media screen and (max-width: 1279px) {
    .banner_ani {
        top: 7%;
        width: 700px;
        .lottie_space {
            z-index: 25;
            height: 400px;
            width: 404px;
            right: calc(50% - 235px);
            top: calc(50% - 295px);
        }
    }
}
@media screen and (max-width: 991px) {
    .banner_ani {
        top: 10%;
        width: 500px;
        .lottie_space {
            z-index: 25;
            height: 305px;
            width: 304px;
            right: calc(50% - 175px);
            top: calc(50% - 220px);
        }
    }
}
@media screen and (max-width: 767px) {
    .banner_ani {
        top: 11%;
        width: 400px;
        .lottie_space {
            z-index: 25;
            height: 240px;
            width: 204px;
            right: calc(50% - 126px);
            top: calc(50% - 175px);
        }
    }
}
@media screen and (max-width: 575px) {
    .banner_ani {
        position: relative;
        top: -48px;
        width: 300px;
        margin: 0 auto;
        .lottie_space {
            z-index: 25;
            height: 160px;
            width: 124px;
            right: calc(50% - 77px);
            top: calc(50% - 115px);
        }
    }
}
.sltns_strtgs_hdng{
    h1{
        font-family: $f_primary;
        font-weight: 400;
        font-size: 64px;
        line-height: 64px;
        letter-spacing: 0.08em;
        color: #FFFFFF;
        max-width: 622px;
    }
    .sltns_txt{
        font-size: 76px;
        letter-spacing: 0.095em;
        background: linear-gradient(276.94deg, #F88AE6 -24.36%, #D280F8 97.36%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }
    .blckchn_txt{
        background: linear-gradient(335.73deg, #FFFFFF -24.2%, #E585EF 130.65%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        display: block;
        letter-spacing: 0.08em;
        font-size: 72px;
        line-height: 64px;
    }
    .sccss_txt{
        background: linear-gradient(276.94deg, #F88AE6 -24.36%, #D280F8 97.36%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        letter-spacing: 0.08em;
        font-size: 72px;
        line-height: 72px;
    }
}
@media screen and (max-width: 1630px) {
    .bckchn_sccss_main_bx{
        min-height: 980px;
        padding: 288px 0 0 0;
    }
}
@media screen and (max-width: 1400px) {
    .bckchn_sccss_main_bx{
        min-height: 920px;
        padding: 240px 0 0 0;
    }
}
@media screen and (max-width: 1279px) {
    .sltns_strtgs_hdng h1{
        font-size: 45px;
        line-height: 65px;
        letter-spacing: 0.04em;
    }
    .sltns_strtgs_hdng .sltns_txt{
        font-size: 56px;
        letter-spacing: 0.095em;
    }
    .sltns_strtgs_hdng .blckchn_txt{
        font-size: 45px;
        line-height: 64px;
        letter-spacing: 0.04em;
    }
    .sltns_strtgs_hdng .sccss_txt{    
        font-size: 45px;
        line-height: 54px;
        letter-spacing: 0.04em;
    }
    .bckchn_sccss_main_bx .def_btn {
        padding: 0 50px !important;
        margin-top: 32px;
    }
    .bckchn_sccss_main_bx {
        min-height: 821px;
        padding: 232px 0 0 0;
        background-position-x: 76% !important;
        background-position: center;
        background-position-y: 32px !important;
    }
        
}
@media screen and (max-width: 991px) {
    .bckchn_sccss_main_bx {
        min-height: 640px;
        padding: 145px 0 0 0;
        background-position-x: 66% !important;
        background-position: center;
        background-position-y: 0px !important;
    }
}
@media screen and (max-width: 767px) {
    // .sltns_strtgs_hdng h1 {
    //     font-size: 44px;
    //     line-height: 42px;
    //     letter-spacing: 0.02em;
    //     max-width: 280px;
    // }
    .sltns_strtgs_hdng .sltns_txt {
        font-size: 44px;
        letter-spacing: 0.055em;
    }
    // .sltns_strtgs_hdng .blckchn_txt {
    //     font-size: 58px;
    //     line-height: 46px;
    //     letter-spacing: 0.02em;
    // }
    // .sltns_strtgs_hdng .sccss_txt {
    //     font-size: 82px;
    //     line-height: 72px;
    //     letter-spacing: 0.02em;
    // }
    .bckchn_sccss_main_bx{
        min-height: 520px;
        padding: 120px 0 0 0;
        background-position-x: 68% !important;
    }
    .bckchn_sccss_main_bx .def_btn {
        padding: 0 32px !important;
        margin-top: 24px;
        min-height: 0 !important;
        height: 36px;
    }
}
@media screen and (max-width: 575px) {
    .bckchn_sccss_main_bx{
        background-position-x: 52% !important;
    }
}
@media screen and (max-width: 480px) {
    .sltns_strtgs_hdng h1 {
        font-size: 32px;
        line-height: 40px;
        letter-spacing: 0.05em;
    }
    .sltns_strtgs_hdng .sltns_txt {
        font-size: 32px;
        letter-spacing: 0.085em;
    }
    .sltns_strtgs_hdng .blckchn_txt {
        font-size: 32px;
        line-height: 40px;
        letter-spacing: 0.04em;
    }
    .sltns_strtgs_hdng .sccss_txt {
        font-size: 32px;
        line-height: 40px;
        letter-spacing: 0.032em;
    }
    .bckchn_sccss_main_bx .def_btn {
        padding: 0 24px !important;
        margin-top: 24px;
        min-height: 0 !important;
        height: 32px;
    }
    .bckchn_sccss_main_bx {
        background-position-x: 53% !important;
        min-height: 420px;
        padding: 110px 0 0 0;
        background-position-y: 16px !important;
    }
}
/// GuruGuidance /////
.home_bg {
    background: url(../img/home_bg.png) no-repeat 0 0;
    background-size: cover;
}
.gr_gdnc_main_bx{
    min-height: 1440px;
    // background: url(../img/guidance_bg.png) no-repeat left 0;
    margin-top: -300px;
    padding-top: 200px;
    padding-bottom: 60px;
}
.innr_gdnc_bx{
    position: relative;
    min-height: 800px;
    max-width: 1201px;
    margin: 0 auto;
    .flex_bx{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .white_html_bx{
        background: #FFFFFF;
        width: 715px;
        padding: 46px 74px 47px 66px;
        position: relative;
        h5{
            font-family: $f_primary;
            font-weight: 400;
            color: #000000;
            font-size: 35px;
            line-height: 40px;
            letter-spacing: 0.02em;
            max-width: 350px;
        }
        p{
            font-family: $f_secondary;
            font-weight: 500;
            font-size: 18px;
            line-height: 28px;
            text-align: justify;
            letter-spacing: 0.02em;
            color: #000000;
            margin-top: 8px;
        }
        .btn_bx{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 100%;
            .def_btn{
                padding: 0 62px !important;
                border: 1px solid #000000 !important;
            }
        }
        .desing_img{
            position: absolute;
            right: 8px;
            top: 8px;
        }
        .chakra_desing_img{
            position: absolute;
            left: 32px;
            top: 300px;
            width: 390px;
        }
    }
    .blue_desing_img{
        margin-left: -312px;
    }
    .abslt_txt{
        position: absolute;
        bottom: 76px;
        right: 36px;
        font-family: $f_secondary;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: 0.02em;
        color: #FFFFFF;
        max-width: 648px;
    }
}
.unlock_fr_p{
    max-width: 905px;
}
@media screen and (max-width: 1630px) {
    .gr_gdnc_main_bx{
        margin-top: -200px;
        padding-top: 280px;
    }
}
@media screen and (max-width: 1279px) {
    .innr_gdnc_bx .blue_desing_img{
        width: 620px;
    }
    .innr_gdnc_bx .white_html_bx {
        background: #FFFFFF;
        width: 660px;
        padding: 20px 36px 46px 36px;
    }
    .innr_gdnc_bx .white_html_bx .desing_img{
        right: 6px;
        top: 6px;
        width: 220px;
    }
    .innr_gdnc_bx .white_html_bx .btn_bx .def_btn{
        padding: 0px 42px !important;
    }
    .innr_gdnc_bx .white_html_bx .chakra_desing_img{
        top: 260px;
        width: 354px;
    }
    .innr_gdnc_bx{
        min-height: 680px;
    }
    .gr_gdnc_main_bx{
        margin-top: -150px;
        padding-top: 260px;
        background-size: 840px;
    }
    .innr_gdnc_bx .abslt_txt{
        bottom: 40px;
        max-width: 568px;
    }
}
@media screen and (max-width: 1100px) {
    .innr_gdnc_bx .white_html_bx {
        width: 610px;
        padding: 16px 24px 24px 24px;
    }
    .innr_gdnc_bx .blue_desing_img {
        width: 580px;
    }
    .innr_gdnc_bx .white_html_bx .chakra_desing_img {
        top: 250px;
        width: 320px;
        left: 24px;
    }
    .innr_gdnc_bx .abslt_txt {
        bottom: 67px;
        max-width: 520px;
        font-size: 20px;
        line-height: 30px;
    }
    .innr_gdnc_bx {
        min-height: 650px;
    }
    .gr_gdnc_main_bx {
        margin-top: -110px;
        padding-top: 220px;
        background-size: 750px;
    }
}
@media screen and (max-width: 991px) {
    .gr_gdnc_main_bx {
        margin-top: -70px;
        padding-top: 200px;
        background-size: 700px;
    }
    .innr_gdnc_bx .flex_bx {
        justify-content: center;
        flex-direction: column;
    }
    .innr_gdnc_bx .blue_desing_img{
        width: 680px;
        margin-top: -40px;
        margin-left: 0;
    }
    .innr_gdnc_bx .abslt_txt {
        bottom: -530px;
        max-width: 552px;
        right: calc(50% - 276px);
        text-align: justify;
    }
    .innr_gdnc_bx .white_html_bx .chakra_desing_img {
        top: 578px;
        width: 320px;
        left: calc(50% - 160px);
    }
}
@media screen and (max-width: 767px) {
    .innr_gdnc_bx .white_html_bx h5 {
        font-size: 29px;
        line-height: 37px;
        letter-spacing: 0.02em;
        max-width: 268px;
        margin-bottom: 10px;
    }
    .innr_gdnc_bx .white_html_bx{
        width: 500px;
        p{
            margin-top: 0px;
            font-size: 16px;
            line-height: 24px;
        }
    }
    .innr_gdnc_bx .white_html_bx .btn_bx{
        margin-top: 24px;
    }
    .innr_gdnc_bx .white_html_bx .desing_img{
        width: 180px;
    }
    .innr_gdnc_bx .blue_desing_img{
        width: 542px;
        margin-top: -30px;
    }
    .innr_gdnc_bx .white_html_bx .chakra_desing_img{
        top: 512px;
    }
    .innr_gdnc_bx .abslt_txt{
        bottom: -470px;
        max-width: 512px;
        right: calc(50% - 256px);

    }
    .gr_gdnc_main_bx{
        background-size: 620px;
    }
}
@media screen and (max-width: 575px) {
    .innr_gdnc_bx .white_html_bx {
        width: 400px;
        padding: 12px 20px 12px 20px;
      
        .btn_bx{
            margin-top: 16px;
            .def_btn{
                padding: 0px 32px !important;
                height: 36px;
                min-height: 0 !important;
            }
        }
    }
    .innr_gdnc_bx .white_html_bx .desing_img {
        width: 154px;
        right: 4px;
        top: 4px;
    }
    .innr_gdnc_bx .blue_desing_img {
        width: 445px;
    }
    .innr_gdnc_bx .white_html_bx .chakra_desing_img {
        top: 445px;
        width: 280px;
        left: calc(50% - 140px);
    }
    .innr_gdnc_bx .abslt_txt{
        bottom: -320px;
        max-width: 436px;
        right: calc(50% - 218px);
        font-size: 18px;
        line-height: 26px;
    }
    .gr_gdnc_main_bx {
        background-size: 520px;
        margin-top: -24px;
    }
    .innr_gdnc_bx .white_html_bx h5 {
        font-size: 23px;
        line-height: 30px;
        letter-spacing: 0.02em;
        max-width: 243px;
        margin-bottom: 10px;
    }
}
@media screen and (max-width: 479px) {
    .innr_gdnc_bx .white_html_bx {
        width: 264px;
        padding: 64px 20px 12px 20px;
    }
    .innr_gdnc_bx .white_html_bx p{
        line-height: 22px;
    }
    .innr_gdnc_bx .white_html_bx .btn_bx {
        margin-top: 24px;
    }
    .innr_gdnc_bx .blue_desing_img {
        width: 286px;
        margin-top: -20px;
    }
    .innr_gdnc_bx .white_html_bx .chakra_desing_img {
        top: 510px;
        width: 190px;
        left: calc(50% - 95px);
    }
    .innr_gdnc_bx .abslt_txt {
        bottom: -351px;
        max-width: 270px;
        right: calc(50% - 135px);
        font-size: 16px;
        line-height: 22px;
    }
    .gr_gdnc_main_bx {
        padding-top: 142px;
        background-size: 390px;
        margin-top: -20px;
    }
    .innr_gdnc_bx .white_html_bx h5 {
        
        margin-top: 10px;
    }
}
///// Roadmap /////
.roadmap_main_bx{
    background: url(../img/roadmap_bg.png) no-repeat right 0;
    min-height: 1500px;
    margin-top: -600px;
    padding-top: 600px;
    .avd_hddn_hdng_bx{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        h3{
            font-family: $f_primary;
            font-weight: 400;
            font-size: 42px;
            line-height: 76px;
            text-align: center;
            letter-spacing: 0.02em;
            color: #FFFFFF;
        }
        p{
            font-family: $f_secondary;
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            text-align: center;
            letter-spacing: 0.02em;
            color: #FFFFFF;
        }
    }
    .roadmap_cube_box{
        background: url(../img/rdmp_cube_frame.png) no-repeat center;
        min-height: 746px;
        max-width: 746px;
        margin: -40px auto 0 auto;
        position: relative;
        &::before{
            content: "";
            background: radial-gradient(50% 50% at 50% 50%, #DF1BFF 0%, rgba(255, 43, 247, 0) 100%);
            opacity: 0.35;
            position: absolute;
            top: 92px;
            left: calc(50% - 496px);
            width: 992px;
            height: 992px;
        }
    }
    .video_b0x{
        position: relative;
        z-index: 9;
        object-fit: cover;
        margin: 0 auto;
    }
}
///// GoItAloneSec /////
.goit_alone_main_bx{
    background: url(../img/goit_alone_bg.png) no-repeat left;
    padding-top: 360px;
    background-position-x: 6%;
    background-position-y: 261px;
    padding-bottom: 240px;
    position: relative;
    &::before{
        content: "";
        background: radial-gradient(50% 50% at 50% 50%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
        opacity: 0.3;
        position: absolute;
        top: 10%;
        left: 4%;
        width: 924px;
        height: 924px;
        border-radius: 50%;
    }
    .two_boxes_bx{
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 1120px;
        margin: 0 auto;
        .dont_goit_bx{
            position: relative;
            background: #FFFFFF;
            padding: 22px 107px 22px 45px;
            width: 716px;
            min-width: 716px;
            h4{
                font-family: $f_primary;
                font-weight: 400;
                font-size: 42px;
                line-height: 39px;
                letter-spacing: 0.02em;
                color: #000000;
            }
            p{
                font-family: $f_secondary;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.02em;
                color: #000000;
                margin-top: 24px;
            }
            .def_btn{
                position: absolute;
                right: 76px;
                bottom: -56px;
                padding: 0 62px !important;
                border: 1px solid #000000 !important;
            }
            .img_frame{
                position: absolute;
                right: 6px;
                top: 6px;
            }
        }
        .blue_img{
            margin-left: -313px;
        }
    }
}
@media screen and (max-width: 1700px) {
    .goit_alone_main_bx{
        background-position-x: 3%;
        background-position-y: 288px;
        background-size: 550px;
    }
}
@media screen and (max-width: 1550px) {
    .goit_alone_main_bx {
        background-position-x: 2%;
        background-position-y: 190px;
        background-size: 520px;
        padding-top: 320px;
    }
    .goit_alone_main_bx::before{
        top: 10%;
        left: 0%;
        width: 800px;
        height: 800px;
    }
}
@media screen and (max-width: 1400px) {
    .goit_alone_main_bx{
        background-position-y: 220px;
        padding-top: 348px;
    }
    .goit_alone_main_bx::before{
        top: 14%;
    }
}
@media screen and (max-width: 1279px) {
    .goit_alone_main_bx .two_boxes_bx{
        flex-direction: column;
    }
    .goit_alone_main_bx .two_boxes_bx .blue_img {
        margin-left: 0;
        margin-top: -30px;
    }
    .goit_alone_main_bx .two_boxes_bx .dont_goit_bx{
        padding: 36px 107px 48px 40px;
        width: 680px;
        min-width: 680px;
    }
    .goit_alone_main_bx .two_boxes_bx .dont_goit_bx .def_btn{
        right: calc(50% - 91px);
        bottom: -22px;
        padding: 0px 48px !important;   
    }
    .goit_alone_main_bx {
        background-position-y: 220px;
        padding-top: 340px;
        background-size: 410px;
        padding-bottom: 80px;
    }
    .goit_alone_main_bx::before {
        top: 6%;
    }
}
@media screen and (max-width: 1100px) {
    .goit_alone_main_bx {
        background-position-y: 118px;
        padding-top: 230px;
        background-size: 380px;
    }
    .goit_alone_main_bx::before {
        top: 2%;
        left: -11%;
    }
}
@media screen and (max-width: 991px) {
    .goit_alone_main_bx .two_boxes_bx .dont_goit_bx .img_frame{
        width: 120px;
    }
    .goit_alone_main_bx .two_boxes_bx .dont_goit_bx {
        padding: 24px 47px 40px 24px;
        width: 620px;
        min-width: 620px;
    }
    .goit_alone_main_bx .two_boxes_bx .blue_img {
        margin-top: -100px;
        width: 680px;
    }
    .goit_alone_main_bx::before {
        top: 0%;
        left: -11%;
        width: 676px;
        height: 676px;
    }
}
@media screen and (max-width: 767px) {
    .goit_alone_main_bx {
        background-position-y: 116px;
        padding-top: 207px;
        background-size: 312px;
    }
    .goit_alone_main_bx .two_boxes_bx .dont_goit_bx {
        padding: 24px 16px 40px 16px;
        width: 490px;
        min-width: 490px;
    }
    .goit_alone_main_bx .two_boxes_bx .dont_goit_bx .def_btn {
        right: calc(50% - 68px);
        bottom: -20px;
        padding: 0px 32px !important;
        min-height: 0 !important;
        height: 36px;
    }
    .goit_alone_main_bx .two_boxes_bx .blue_img {
        margin-top: -80px;
        width: 524px;
    }
    .goit_alone_main_bx::before {
        top: 0%;
        left: -12%;
        width: 567px;
        height: 567px;
    }
}
@media screen and (max-width: 575px) {
    .goit_alone_main_bx::before {
        top: 0%;
        left: -12%;
        width: 420px;
        height: 420px;
    }
    .goit_alone_main_bx .two_boxes_bx .blue_img {
        margin-top: -20px;
        width: 444px;
    }
    .goit_alone_main_bx .two_boxes_bx .dont_goit_bx {
        padding: 20px 16px 40px 16px;
        width: 420px;
        min-width: 420px;
        h4{
            font-size: 32px;
            line-height: 32px;
        }
        p{
            font-size: 16px;
            line-height: 22px;
            margin-top: 12px;
        }
    }
    .goit_alone_main_bx {
        background-position-y: 60px;
        padding-top: 163px;
        background-size: 280px;
    }    
}
@media screen and (max-width: 479px) {
    .goit_alone_main_bx {
        background-position-y: 20px;
        padding-top: 132px;
        background-size: 260px;
    }
    .goit_alone_main_bx::before {
        top: 0%;
        left: -6%;
        width: 300px;
        height: 300px;
    }
    .goit_alone_main_bx .two_boxes_bx .blue_img {
        margin-top: -10px;
        width: 288px;
    }
    .goit_alone_main_bx .two_boxes_bx .dont_goit_bx {
        padding: 16px 12px 36px 12px;
        width: 310px;
        min-width: 310px;
        h4{
            font-size: 28px;
            line-height: 28px;
        }
        p{
            font-size: 14px;
            line-height: 20px;
            margin-top: 10px;
        }
    }
}
///// StartNowSec /////
.startnow_main_bx{
    position: relative;
    padding-top: 140px;
    padding-bottom: 120px;
    overflow: hidden;
    &::before{
        content: "";
        position: absolute;
        z-index: -1;
        top: calc(50% - 600px);
        left: -12%;
        background: radial-gradient(50% 50% at 50% 50%, #DF1BFF 0%, rgba(255, 43, 247, 0) 100%);
        opacity: 0.3;
        width: 1200px;
        height: 1200px;
        border-radius: 50%;
    }
    &::after{
        content: "";
        position: absolute;
        z-index: -1;
        top: calc(50% - 430px);
        right: -15%;
        background: radial-gradient(50% 50% at 50% 50%, #DF1BFF 0%, rgba(255, 43, 247, 0) 100%);
        opacity: 0.2;
        width: 858px;
        height: 858px;
        border-radius: 50%;
    }
    .chakra-container{
        max-width: 1780px !important;
    }
    .strtnw_innr_bx{
        display: flex;
        align-items: center;
        justify-content: center;
        .left_design{
            margin-right: -8px;
        }
        .right_design{
            margin-left: -8px;
        }
    }
}
.strtnw_innr_bx{
    .srtnw_white_bx{
        max-width: 1194px;
        width: 100%;
        min-height: 807px;
        background: #FFFFFF;
        // background: #FFFFFF url(../img/strtnw_whitebx_bg.png) no-repeat center center;
        background-size: 1088px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        &::before{
            content: "";
            position: absolute;
            bottom: -16%;
            right: -20%;
            background: radial-gradient(50% 50% at 50% 50%, #FFD701 0%, rgba(255, 215, 1, 0) 100%);
            opacity: 0.2;
            width: 856px;
            height: 856px;
            border-radius: 50%;
        }
        .strtnw_lotti_box {
            position: absolute; 
            top: 0;
            left: 0;
            width: 100%;
            opacity: 0.5;
        }
        h5{
            font-family: $f_primary;
            font-weight: 400;
            font-size: 42px;
            line-height: 76px;
            text-align: center;
            letter-spacing: 0.02em;
            color: #000000;
            z-index: 1;
        }
        .the_jrny_txt{
            font-family: $f_secondary;
            font-weight: 700;
            font-size: 20px;
            line-height: 28px;
            text-align: center;
            letter-spacing: 0.02em;
            color: #000000;
            max-width: 360px;
            margin: 24px 0 50px 0;
            z-index: 1;
        }
        .three_boxex{
            display: flex;
            align-items: center;
            z-index: 1;
            .pddng_dcrs{
                padding-bottom: 17px !important; 
            }
            .same_box{
                position: relative;
                background: #FFFFFF url(../img/three_boxes_bg.png) no-repeat center bottom;
                margin: 0 13px;
                max-width: 262px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                padding: 0 34px 43px 34px;
                box-shadow: 0px 22.6207px 56.5517px rgba(69, 37, 64, 0.3);
                &::before{
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    margin: -1px;
                    border: 1px solid transparent !important;
                    background: linear-gradient(90deg, #FF6B00 16.98%, #FEE500 100.02%) border-box;
                    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
                    mask-composite: exclude;
                    -webkit-mask-composite: destination-out;    
                    pointer-events: none;
                    border: 1px solid;
                }
                .yllw_grdnt_bx{
                    width: 143.64px;
                    height: 115.37px;
                    background: linear-gradient(150.13deg, #FF6B00 -2.76%, #FEE500 81.14%);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    &::before{
                        content: "";
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        opacity: 0.2;
                        background: url(../img/strtnw_numbers_bg.png) no-repeat center center;
                    }
                    h2{
                        font-family: $f_secondary;
                        font-weight: 700;
                        font-size: 52.0276px;
                        line-height: 98.66%;
                        text-align: center;
                        letter-spacing: 0.02em;
                        color: #000000;
                    }
                }
                p{
                    font-family: $f_secondary;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 26px;
                    text-align: center;
                    letter-spacing: 0.02em;
                    color: #000000;
                    margin-top: 36px;
                }
            }
        }
        .strtnw_cntctus_btn{
            background: linear-gradient(90deg, #7A0C69 7.54%, #684C8C 100.02%) !important;
            color: #FFFFFF !important;
            padding: 0 62px !important;
            margin-top: 134px !important;
        }
    }
}
@media screen and (max-width: 1650px) {
    .startnow_main_bx .strtnw_innr_bx .right_design {
        margin-left: -8px;
        width: 248px;
    }
    .startnow_main_bx .strtnw_innr_bx .left_design {
        margin-right: -8px;
        width: 248px;
    }
    .strtnw_innr_bx .srtnw_white_bx{
        min-height: 700px;
    }
}
@media screen and (max-width: 1400px) {
    .strtnw_innr_bx .srtnw_white_bx .three_boxex .same_box{
        margin: 0px 12px;
        max-width: 222px;
        padding: 0 12px 43px 12px;
    }
    .strtnw_innr_bx .srtnw_white_bx .three_boxex .same_box .yllw_grdnt_bx{
        width: 125.64px;
        height: 99.37px;
    }
}
@media screen and (max-width: 1279px) {
    .strtnw_innr_bx .srtnw_white_bx{
        margin: -210px auto;
    }
    .startnow_main_bx .strtnw_innr_bx {
        flex-direction: column;
    }
    .startnow_main_bx .strtnw_innr_bx .left_design{
        transform: rotate(90deg);
        margin-right: 0px;
    }
    .startnow_main_bx .strtnw_innr_bx .right_design{
        transform: rotate(90deg);
        margin-left: 0px;
    }
    .startnow_main_bx::before{
        width: 980px;
        height: 980px;
        top: 3%;
        left: calc(50% - 490px);
    }
    .startnow_main_bx::after{
        top: auto;
        bottom: 10%;
        right: calc(50% - 300px);
        width: 600px;
        height: 600px;
    }
    .strtnw_innr_bx .srtnw_white_bx::before{
        bottom: -20%;
        right: calc(50% - 300px);
        width: 600px;
        height: 600px;
    }
    .startnow_main_bx{
        padding-top: 0px;
        padding-bottom: 10px;
    }
}
@media screen and (max-width: 991px) {
    .startnow_main_bx{
        margin-bottom: -220px;
    }
}
@media screen and (max-width: 767px) {
    .strtnw_innr_bx .srtnw_white_bx .three_boxex{
        flex-direction: column;
        justify-content: center;
    }
    .strtnw_innr_bx .srtnw_white_bx{
        padding: 40px 0;
    }
    .strtnw_innr_bx .srtnw_white_bx .the_jrny_txt{
        margin: 12px 0 36px 0;
    }
    .strtnw_innr_bx .srtnw_white_bx .three_boxex .same_box{
        margin: 20px 0;
    }
    .strtnw_innr_bx .srtnw_white_bx .strtnw_cntctus_btn{
        margin-top: 48px !important;
        padding: 0 48px !important;
    }
    .strtnw_innr_bx .srtnw_white_bx::before{
        bottom: -14%;
    }
    .startnow_main_bx .strtnw_innr_bx .left_design{
        width: 200px;
    }
    .startnow_main_bx .strtnw_innr_bx .right_design{
        width: 200px;
    }
    .strtnw_innr_bx .srtnw_white_bx {
        margin: -170px auto;
    }
    .startnow_main_bx::before {
        width: 600px;
        height: 600px;
        top: 3%;
        left: calc(50% - 300px);
    }
}
@media screen and (max-width: 575px) {
    .startnow_main_bx .strtnw_innr_bx .left_design {
        width: 168px;
    }
    .startnow_main_bx .strtnw_innr_bx .right_design{
        width: 168px;
    }
    .strtnw_innr_bx .srtnw_white_bx {
        margin: -142px auto;
        padding: 20px 0;
    }
    .strtnw_innr_bx .srtnw_white_bx h5{
        font-size: 36px;
        line-height: 40px;
    }
    .strtnw_innr_bx .srtnw_white_bx .the_jrny_txt{
        font-size: 18px;
        line-height: 22px;
        max-width: 324px;
        margin: 12px 0 20px 0;
    }
    .strtnw_innr_bx .srtnw_white_bx .strtnw_cntctus_btn {
        margin-top: 24px !important;
    }
    .startnow_main_bx::after{
        bottom: 8%;
        right: calc(50% - 150px);
        width: 300px;
        height: 300px;
    }
    .strtnw_innr_bx .srtnw_white_bx::before {
        bottom: -12%;
        right: calc(50% - 150px);
        width: 300px;
        height: 300px;
    }
    .startnow_main_bx::before {
        width: 300px;
        height: 300px;
        top: 5%;
        left: calc(50% - 150px);
    }
}
@media screen and (max-width: 479px) {
    .startnow_main_bx .strtnw_innr_bx .left_design {
        width: 108px;
    }
    .startnow_main_bx .strtnw_innr_bx .right_design {
        width: 108px;
    }
    .strtnw_innr_bx .srtnw_white_bx {
        margin: -90px auto;
        padding: 16px 0;
    }
    .strtnw_innr_bx .srtnw_white_bx .three_boxex .same_box .yllw_grdnt_bx {
        width: 108.64px;
        height: 84.37px;
        h2{
            font-size: 42.0276px;
            line-height: 92.66%;
        }
    }
    .strtnw_innr_bx .srtnw_white_bx .three_boxex .same_box {
        margin: 16px 0;
    }
    .startnow_main_bx::after{
        bottom: 8%;
        right: calc(50% - 100px);
        width: 200px;
        height: 200px;
    }
    .strtnw_innr_bx .srtnw_white_bx::before {
        bottom: -5%;
        right: calc(50% - 100px);
        width: 200px;
        height: 200px;
    }
    .startnow_main_bx::before {
        width: 200px;
        height: 200px;
        top: 5%;
        left: calc(50% - 100px);
    }
    .strtnw_innr_bx .srtnw_white_bx h5 {
        font-size: 32px;
        line-height: 36px;
    }
    .strtnw_innr_bx .srtnw_white_bx .the_jrny_txt {
        font-size: 16px;
        line-height: 20px;
        max-width: 248px;
        margin: 8px 0 16px 0;
    }
    .strtnw_innr_bx .srtnw_white_bx .three_boxex .same_box p{
        font-size: 16px;
        line-height: 20px;
        margin-top: 30px;
    }
    .strtnw_innr_bx .srtnw_white_bx .strtnw_cntctus_btn{
        padding: 0 32px !important;
        min-height: 0 !important;
        height: 34px;
    }
}
///// Faq /////
.faq_main_bx{
    // background: url(../img/faq_bg.png) no-repeat right 0;
    position: relative;
    padding-top: 140px;
    padding-bottom: 60px;
    overflow: hidden;
    // &::before{
    //     content: "";
    //     position: absolute;
    //     top: -15%;
    //     right: -15%;
    //     background: radial-gradient(50% 50% at 50% 50%, #DF1BFF 0%, rgba(255, 43, 247, 0) 100%);
    //     opacity: 0.25;
    //     width: 1166px;
    //     height: 1166px;
    //     border-radius: 50%;
    // }
    .frame_hdng_bx{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h3{
            font-family: $f_primary;
            font-weight: 400;
            font-size: 56px;
            line-height: 101px;
            text-align: center;
            letter-spacing: 0.02em;
            color: #FFFFFF;
            margin-bottom: 80px;
        }
    }
    .grid_bx{
        max-width: 1194px;
        margin: 0 auto;
        .same_innr_faq{
            position: relative;
            padding-left: 18px;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            justify-content: flex-start;
            width: 373px;
            height: 210px;
            &::before{
                content: '';
                position: absolute;
                left: 0;
                top: calc(50% - 90px);
                background: linear-gradient(335.73deg, #FFFFFF -24.2%, #E585EF 130.65%);
                width: 4px;
                height: 204px;
            }
            h5{
                font-family: $f_primary;
                font-weight: 400;
                font-size: 30px;
                line-height: 54px;
                letter-spacing: 0.02em;
                color: #FFFFFF;
            }
            p{
                font-family: $f_secondary;
                font-weight: 500;
                font-size: 16px;
                line-height: 22px;
                letter-spacing: 0.02em;
                color: #FFFFFF;
                max-width: 348px;
            }
        }
    }
}
@media screen and (max-width: 1530px) {
    .faq_main_bx::before{
        top: -13%;
        right: -18%;
        width: 1000px;
        height: 1000px;  
    }
    .faq_main_bx{
        background-size: 580px;
    }
}
@media screen and (max-width: 1400px) {
    .faq_main_bx{
        background-size: 480px;
        padding-top: 100px;
    }
    .faq_main_bx::before {
        top: -10%;
        right: -16%;
        width: 800px;
        height: 800px;
    }
}
@media screen and (max-width: 1279px) {
    .faq_main_bx .grid_bx .rspnsv_bx{
        grid-template-columns: repeat(2, 1fr) !important;
        grid-gap: 20px !important;
    }
    .faq_main_bx .grid_bx .grd_itm_bx{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .faq_main_bx{
        margin-top: -160px;  
    }
    .faq_main_bx .frame_hdng_bx h3{
        margin-bottom: 60px;
    }
    .faq_main_bx::before{
        top: -7%;   
    }
}
@media screen and (max-width: 850px) {
    .faq_main_bx .grid_bx .rspnsv_bx{
        grid-template-columns: repeat(1, 1fr) !important;
        grid-gap: 20px !important;
    }
    .faq_main_bx .grid_bx .grd_itm_bx{
        align-items: flex-start;
    }
    .faq_main_bx::before {
        top: -4%;
        right: -20%;
        width: 680px;
        height: 680px;
    }
    .faq_main_bx {
        background-size: 412px;
        padding-top: 100px;
    }
}
@media screen and (max-width: 767px) {
    .startnow_main_bx {
        margin-bottom: -180px;
    }
    .faq_main_bx {
        background-size: 380px;
        padding-top: 80px;
        background-position-y: 50px;
    }
    .faq_main_bx::before{
        right: -30%;
    }
    .faq_main_bx .frame_hdng_bx h3 {
        margin-bottom: 24px;
    }
}
@media screen and (max-width: 479px) {
    .faq_main_bx .frame_hdng_bx{
        img{
            width: 216px;
        }
        h3{
            font-size: 42px;
            line-height: 66px;
        }
    }
    .faq_main_bx{
        padding-top: 120px;
        background-size: 300px;
        background-position-y: 125px;
    }
    .faq_main_bx .grid_bx .same_innr_faq{
        width: 300px;
        height: 192px;
    }
    .faq_main_bx .grid_bx .same_innr_faq h5{
        font-size: 24px;
        line-height: 32px;
    }
    .faq_main_bx .grid_bx .same_innr_faq p{
        font-size: 14px;
        line-height: 20px;
        max-width: 280px;
    }
    .faq_main_bx::before {
        top: 2%;
        right: -36%;
        width: 520px;
        height: 520px;
    }
    .faq_main_bx .grid_bx .same_innr_faq{
        justify-content: center;
    }
}
///// ContactUs /////
.cntctus_main_bx{
    // background: url(../img/cntctus_top_frame.png) no-repeat left 0;
    padding-top: 620px;
    margin-top: -376px;
    position: relative;
    // &::before{
    //     content: "";
    //     position: absolute;
    //     top: -37%;
    //     left: -39%;
    //     background: radial-gradient(50% 50% at 50% 50%, #DF1BFF 0%, rgba(255, 43, 247, 0) 100%);
    //     opacity: 0.39;
    //     width: 1818px;
    //     height: 1818px;
    //     border-radius: 50%;
    // }
}
.cntctus_innr_bx{
    position: relative;
    background: url(../img/cntctus_innr_bg.jpg) no-repeat center 0;
    background-size: cover;
    max-width: 923px;
    padding: 48px 12px 53px 12px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // overflow: hidden;
    .cntctus_lotti_box {
        position: absolute;
        right: -69px;
        top: -19px;
        width: 68%;
        @media screen and (max-width: 575px) {
            right: -53px;
            top: -14px;
            width: 90%;
        }
    }
    .hdng_cntnt{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 1;
        h3{
            font-family: $f_primary;
            font-weight: 400;
            font-size: 42px;
            line-height: 48px;
            text-align: center;
            letter-spacing: 0.02em;
            color: #000000;
            span{
                background: linear-gradient(90deg, #7A0C69 7.54%, #684C8C 100.02%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
            }
        }
        p{
            font-family: $f_secondary;
            font-weight: 700;
            font-size: 20px;
            line-height: 36px;
            text-align: center;
            letter-spacing: 0.02em;
            color: #000000;
        }
    }
    .cntctus_logo{
        position: absolute;
        top: -137px;
        left: -76px;
        z-index: 1;
    }
    .inpts_box{
        margin-top: 60px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        z-index: 1;
        .same_inpt{
            margin: 15px 0;
            max-width: 354px;
            width: 100%;
            height: 47.41px;
            .chakra-input, .txt_area{
                background: #FFFFFF;
                border: 1px solid #000000;
                border-radius: 0;
                min-width: 354px;
                width: 100%;
                height: 47.41px !important;
                font-family: $f_secondary   ;
                font-weight: 300;
                font-size: 14px;
                letter-spacing: 0.02em;
                color: #000000;
                padding: 0 13px;
                box-shadow: none !important;
            }
            .chakra-select{
                background: #FFFFFF;
                border: 1px solid #000000;
                border-radius: 0;
                width: 354px;
                height: 47.41px;
                font-family: $f_secondary   ;
                font-weight: 300;
                font-size: 14px;
                letter-spacing: 0.02em;
                color: #000000;
                padding: 0 13px;
                box-shadow: none !important;
            }
            .txt_area{
                min-height: 172px;
                outline: none !important;
                resize: none;
            }
        }
        .lft_inpt{
            margin-right: 13px;
        }
    }
    .send_btn_bx{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 24px;
        .send_btn{
            background: #000000;
            font-family: $f_primary;
            font-weight: 400;
            font-size: 20px;
            text-align: center;
            letter-spacing: 0.02em;
            color: #FFFFFF;
            border-radius: 0;
            width: 312px;
            height: 42.7px;
            &:hover, &:focus{
                background: #76186f;
            }
        }
    }
}
@media screen and (max-width:1280px) {
    .cntctus_main_bx::before{
        width: 100%;
        left: 0;
    }
}
@media screen and (max-width:1199px) {
    .cntctus_innr_bx .cntctus_logo {
        position: absolute;
        top: -313px;
        left: auto;
        margin-top: 50px;
    }
}
@media screen and (max-width:767px) {
    .cntctus_innr_bx .inpts_box{
        flex-direction: column;
        margin-top: 30px;
        width: 100%;
    }
    .cntctus_innr_bx .send_btn_bx{
        // margin-top: 124px;
    }
    .cntctus_innr_bx .inpts_box .lft_inpt {
        margin-right: 0px;
        width: 100%;
    }
    .cntctus_innr_bx .inpts_box .same_inpt{
        height: auto;
        max-width: 100%;
    }
    .cntctus_innr_bx .inpts_box .same_inpt .chakra-select{
        width: 100%;
    }
    .rght_inpt{
        width: 100% !important;
    }
    .cntctus_innr_bx{
        padding: 45px 12px 35px 12px;
    }
    .cntctus_innr_bx .inpts_box .same_inpt .chakra-input{
        min-width: inherit !important;
    }
    .cntctus_innr_bx .inpts_box .same_inpt .txt_area{
        min-width: inherit !important;
    }
}
@media screen and (max-width:575px) {
    .cntctus_innr_bx .cntctus_logo {
        width: 250px;
        /* height: auto; */
        margin-top: 0;
        top: -186px;
    }
    .cntctus_innr_bx{
        padding: 45px 12px 25px 12px;
    }
    .cntctus_innr_bx .hdng_cntnt h3{
        font-size: 30px;
        line-height: 32px;

    }
    .cntctus_innr_bx .inpts_box{
        margin-top: 7px;
    }
    .cntctus_innr_bx .send_btn_bx{
        margin-top: 7px;
    }
}
.video_box_main{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    video {
        mix-blend-mode: exclusion;
    }
}
.cntctus_main_bx .left_img_aj{
    position: absolute;
    right: 0;
    bottom: 115px;
    width: 411px;
    height: 484px;
}
.foot_up_marqe{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    .marquee_bgrnd{
        background: #101010 !important;
        img{
            margin: 0 8px;
        }
        p{
            background: linear-gradient(335.73deg, #FFFFFF -24.2%, #E585EF 130.65%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            opacity: 0.4;
            font-family: 'Koulen';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 36px;
            text-align: center;
            letter-spacing: -0.03em;
        }
    }
   
}
.footer .foote_bg_pernt{
    background: #232323;
    position: relative;
    .firs_img_set{
        position: absolute;
        left: 33px;
        bottom: 17%;
    }
    .second_img_set{
        position: absolute;
        right: 33px;
        bottom: 17%;
    }
}
.logo_and_text_box{
    position: relative;
    margin-top: -53px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 2;
    img{
        box-shadow: 0px 5px 18px rgba(255, 4, 245, 0.25);
    }
    p{
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0.02em;
        color: #FF18DA;
        margin-top: 8px;
    }
}
.three_items_content{
    padding-bottom: 60px;
    padding-top: 38px;
}
.first_content{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
   
    h3{
        font-family: 'Koulen';
        font-style: normal;
        font-weight: 400;
        font-size: 34px;
        line-height: 61px;
        letter-spacing: -0.03em;
        color: #FFFFFF;
        margin-bottom: 4px;
    }
    a{
        span {
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 400;
            font-size: 17.0049px;
            line-height: 20px;
            text-decoration: underline !important;
            background: linear-gradient(108.11deg, #FFFFFF 14.2%, #E585EF 117.83%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            margin: 0 9px 0 6px;
            // border-bottom: 1px solid #fff;
        }
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.fotr_last_pera{
    p{
        // font-family: 'Inter';
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 26px;
        text-align: center;
        letter-spacing: -0.03em;
        color: #7D7D7D;
    }
}
@media screen and (max-width:991px) {
    .logo_and_text_box{
        img{
            width: 300px !important;
        }
        p{
            font-size: 13px;
            margin-top: 0;
        }
    }
}
@media screen and (max-width: 767px){
    .logo_and_text_box img {
        width: 240px !important;
    }
}
@media screen and (max-width: 575px){
    .logo_and_text_box img {
        width: 200px !important;
    }
    .logo_and_text_box p {
        font-size: 12px;
    }
    .three_items_content {
        padding-bottom: 21px;
        padding-top: 14px;
    }
    .first_content h3{
        font-size: 26px;
        line-height: 40px;
        margin-bottom: 0;
    }
    .first_content a{
        margin-bottom: 20px;
        &:last-child{
            margin-bottom: 0;
        }
    }
    .footer .foote_bg_pernt .second_img_set{
        display: none;
    }
    .footer .foote_bg_pernt .firs_img_set{
        left: calc(50% - 93px);
    }
}

// ajit makwana


.avoid_hdden_up_content{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    img{
        margin-bottom: 2px;
    }
    h1{
        font-family: 'Koulen';
        font-style: normal;
        font-weight: 400;
        font-size: 42px;
        line-height: 76px;
        text-align: center;
        letter-spacing: 0.02em;
        color: #FFFFFF;
    }
    p{
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        text-align: center;
        letter-spacing: 0.02em;
        color: #FFFFFF;
    }
}
.centr_content_prnt{
    position: relative;
    .avod_centr_bg{
        margin: -43px auto 0 auto;
    }
    .avoid_video{
        position: absolute;
        top: 6%;
        left: calc(50% - 347px);
        .video_pernt{
            mix-blend-mode: lighten;
        }
    }
    .all_hed_pera_css_prnt{
        .roadmap_box{
            position: absolute;
            top: 26%;
            left: 35px;
            h4{ margin-bottom: -61px;}
            p{ margin-top: -72px;}
        }
        .avoid_legal_box{
            position: absolute;
            top: 60%;
            left: 8%;
            h4{ margin-bottom: -75px;}
        }
        .increase_box{
            position: absolute;
            right: 8px;
            top: 26%;
            h4{
                margin-left: 55%;
                margin-bottom: -57px;
            }
            p{
                margin-left: 269px;
                margin-top: -76px;
            }
        }
        .make_lasting_box{
            position: absolute;
            top: 60%;
            right: 60px;
            h4{
                margin-left: 55%;
                margin-bottom: -69px;
            }
            p{
                margin-left: 289px;
            }
        }
        .def_heding{
            font-family: 'Koulen';
            font-style: normal;
            font-weight: 400;
            font-size: 30px;
            line-height: 98.66%;
            text-align: center;
            letter-spacing: 0.02em;
            color: #FFFFFF;
            max-width: 217px;
           
        }
        .def_peragrp{
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            text-align: justify;
            letter-spacing: 0.02em;
            color: #FFFFFF;
            max-width: 227px;
            
        }
    }
}
@media screen and (max-width:1199px) {
    .avoid_hdden_up_content h1{
        font-size: 33px;
        line-height: 54px;
    }
    .avoid_hdden_up_content p{font-size: 21px;}
    .centr_content_prnt .all_hed_pera_css_prnt .def_heding{
        font-size: 22px;
        max-width: 177px;
    }
    .centr_content_prnt .all_hed_pera_css_prnt .roadmap_box h4 {
        margin-left: 26px;
    }
    .centr_content_prnt .all_hed_pera_css_prnt .avoid_legal_box h4 {
        margin-left: 24px;
    }
    .centr_content_prnt .all_hed_pera_css_prnt .increase_box h4 {
        margin-left: 59%;
    }
    .centr_content_prnt .all_hed_pera_css_prnt .make_lasting_box h4 {
        margin-left: 61%;
    }
}
@media screen and (max-width:991px) {
    .dip_none_pernt{
        display: none !important;
    }
    .all_hed_pera_css_prnt{
        display: grid;
        grid-gap: 30px;
        grid-template-columns: repeat(2, minmax(0px, 1fr));
    }
    .centr_content_prnt .all_hed_pera_css_prnt .roadmap_box{
        position: relative;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .centr_content_prnt .all_hed_pera_css_prnt .roadmap_box h4{
        margin-bottom: 0;
        margin-left: 0;
    }
    .centr_content_prnt .all_hed_pera_css_prnt .roadmap_box p {
        margin-top: 0;
    }
    .centr_content_prnt .all_hed_pera_css_prnt .avoid_legal_box {
        position: relative;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .centr_content_prnt .all_hed_pera_css_prnt .avoid_legal_box h4{
        margin-left: 0;
        margin-bottom: 0;
    }
    .centr_content_prnt .all_hed_pera_css_prnt .increase_box {
        position: relative;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .centr_content_prnt .all_hed_pera_css_prnt .increase_box h4{
        margin-left: 0;
        margin-bottom: 0;
    }
    .centr_content_prnt .all_hed_pera_css_prnt .increase_box p {
        margin-left: 0;
        margin-top: 0;
    }
    .centr_content_prnt .all_hed_pera_css_prnt .make_lasting_box {
        position: relative;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .centr_content_prnt .all_hed_pera_css_prnt .make_lasting_box h4{
        margin-left: 0;
        margin-bottom: 0;
    }
    .centr_content_prnt .all_hed_pera_css_prnt .make_lasting_box p {
        margin-left: 0;
    }
    .centr_content_prnt .all_hed_pera_css_prnt .def_heding{
        max-width: 100%;
    }
    .centr_content_prnt .all_hed_pera_css_prnt .def_peragrp{
        max-width: 100%;
        text-align: center;
    }
    .centr_content_prnt .avoid_video{
        top: 2%;
    }
}
@media screen and (max-width:767px) {
    .avoid_hdden_up_content h1 {
        font-size: 23px;
        line-height: 23px;
        margin-top: 5px;
    }
    .avoid_hdden_up_content p {
        font-size: 17px;
    }
    // .centr_content_prnt .avoid_video{
    //     top: -3%;
    // }
}
@media screen and (max-width:575px) {
    .all_hed_pera_css_prnt{
        display: grid;
        grid-gap: 30px;
        grid-template-columns: repeat(1, minmax(0px, 1fr));
    }
    .centr_content_prnt .avoid_video{
        left: 0;
    }
    .avoid_hdden_up_content p {
        font-size: 12px;
        line-height: 22px;
    }
    .avoid_hdden_up_content h1 {
        font-size: 20px;
        line-height: 24px;
    }
}

.def_select {
    background: linear-gradient(180deg, rgba(68, 68, 68, 0.15) 0%, rgba(0, 0, 0, 0) 100%);
    border: 1px solid #525252 !important;
    border-radius: 73px;
    min-height: 45px;
    box-shadow: none !important;
  }

.select_wrap {
    background: #FFFFFF;
    border: 1px solid #000000;
    border-radius: 0;
    min-width: 354px;
    width: 100%;
    height: 47.41px !important;
    font-family: "Mulish";
    font-weight: 300;
    font-size: 14px;
    letter-spacing: 0.02em;
    color: #000000;
    box-shadow: none !important;
    & > div {
        width: 100%;
        height: 45px;
        & > div {
            box-shadow: none;
            border: none;
            outline: none;
            height: 100%;
            background-color: white;
            & > div {
                background-color: white;
            }
        }
    }
}

.lott_postn {position: relative;}
.hm_bg_lotti_anmtns {
    position: absolute;
    left: -180px;
    top: -455px;
    pointer-events: none;
    svg {
        mix-blend-mode: hard-light;
    }
}
.h_flex{
    display: flex;
}
.for_max_widh{
    max-width: 590px;
    width: 100%;
    display: block;
}
.powr_mrg{
    margin-left: 17px;
}
@media screen and (max-width: 1440px) {
    .hm_bg_lotti_anmtns {
        top: -540px;
    }
}
@media screen and (max-width: 1199px) {
    .hm_bg_lotti_anmtns {
        top: -480px;
    }
}
@media screen and (max-width: 767px) {
    .hm_bg_lotti_anmtns {
        top: -380px;
        left: -50px;
    }
    .select_wrap {
        min-width: inherit;
    }
}
@media screen and (max-width: 480px) {
    .hm_bg_lotti_anmtns {
        top: -280px;
    }
}